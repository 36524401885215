'use client';

import { useAppKit, useAppKitState } from '@reown/appkit/react';
import { CheckCircle, ChevronDown, Pencil } from 'lucide-react';
import { FC, HTMLAttributes, forwardRef, useEffect } from 'react';
import { normalize } from 'viem/ens';
import {
  useAccount,
  useEnsAvatar,
  useEnsName,
  useSignMessage,
  useVerifyMessage,
} from 'wagmi';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Button } from '~/components/ui/button';
import { useToast } from '~/components/ui/use-toast';
import { cn } from '~/lib/utils';
import nanoid from '~/utils/nanoid';

import { Loader, Wallet } from '../../common';

type LoadingStateProps = HTMLAttributes<HTMLSpanElement>;

const LoadingState: FC<LoadingStateProps> = (props) => {
  return (
    <span className="text-lg text-black/80" {...props}>
      Connecting...
    </span>
  );
};

type ConnectedStateProps = HTMLAttributes<HTMLSpanElement> & {
  address: string;
  font: string;
};

const ConnectedState: FC<ConnectedStateProps> = ({
  address,
  font,
  ...props
}) => {
  return (
    <span
      className="text-medium flex h-[30px] min-w-0 flex-1 items-center overflow-clip rounded-10 bg-[rgba(52,52,52,0.1)] px-2.5 text-lg text-black/80"
      {...props}
    >
      <span
        className={cn(
          'min-w-[30px] flex-1 overflow-clip overflow-ellipsis',
          font,
        )}
      >
        {address.slice(0, -3)}
      </span>
      <span>{address.slice(-3)}</span>
      <ChevronDown size={20} className="ml-2" />
    </span>
  );
};

type NotConnectedStateProps = HTMLAttributes<HTMLSpanElement> & {
  font: string;
};

const NotConnectedState: FC<NotConnectedStateProps> = ({ font }, props) => {
  return (
    <span className={cn('text-medium text-lg text-black', font)} {...props}>
      Connect Wallet
    </span>
  );
};

export type WalletValue = {
  signature: `0x${string}`;
  address: `0x${string}`;
  message: string;
  chainId?: number;
} | null;

type ConnectWalletProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: WalletValue) => void;
  // The signature from signing the message
  value?: WalletValue;
  disabled?: boolean;
  className?: string;
  config?: {
    message?: string;
    chainId?: number;
  };
  font: string;
};

const ConnectWallet = forwardRef<HTMLDivElement, ConnectWalletProps>(
  ({ onChange, value, disabled, className, config, font, ...props }, ref) => {
    const { theme, form } = useFormBuilder();
    const { signMessageAsync, isPending: isSigning, error } = useSignMessage();
    const { open } = useAppKit();
    const { open: isOpen } = useAppKitState();
    const { address, isConnected, status } = useAccount();
    const { data: name } = useEnsName({
      address,
      query: {
        enabled: !!address,
      },
    });
    const { data: avatar } = useEnsAvatar({
      name: normalize(name ?? ''),
    });
    const { data: isVerified } = useVerifyMessage({
      address: address,
      message: value?.message,
      signature: value?.signature,
      chainId: value?.chainId ?? config?.chainId ?? 1,
    });

    const { toast } = useToast();

    const handleConnect = () => {
      // Open the wallet modal
      open();
    };

    const handleSign = async () => {
      try {
        if (!address || !form) {
          return;
        }

        // Using the EIP-4361 message format
        const message = `app.formo.so wants you to sign in with your account:\n${address}\n\nSigning this message allows you to verify your ownership of this wallet. Signing is a safe, gas-less transaction that does not in any way give Formo permission to perform any transactions with your wallet.\n\nURI: ${location.href}\nVersion: 1\nChain ID: ${config?.chainId ?? 1}\nNonce: ${nanoid()}\nIssued At: ${new Date().toISOString()}\nForm ID: ${form?.id}\n${config?.message || ''}`;

        const signature = await signMessageAsync({
          message,
        });

        onChange?.({
          address,
          message,
          signature,
          chainId: config?.chainId ?? 1,
        });
      } catch (error) {
        console.error('Failed to sign message', error);
      }
    };

    useEffect(() => {
      // Reset the value when the wallet is disconnected
      if (status === 'disconnected') {
        onChange?.(null);
      }
    }, [status, onChange]);

    useEffect(() => {
      if (!error) return;
      if (error?.message.includes('cancelled')) {
        toast({
          title: 'User cancelled the request',
        });
      } else {
        toast({
          title: 'Error signing the message',
          description: error?.message,
        });
      }
    }, [error]);

    return (
      <div
        ref={ref}
        className={cn('flex w-max max-w-full items-center gap-5', className)}
        {...props}
      >
        <Button
          type="button"
          className={cn(
            'h-12 min-w-0 flex-1 justify-start gap-2.5 whitespace-normal !border-1.5 border-gray-300 px-4 py-2 text-black transition-all',
            theme?.radius ? theme?.radius : 'rounded-md',
          )}
          variant="outline"
          onClick={handleConnect}
          disabled={disabled || isSigning}
        >
          <div className="flex h-[30px] w-[30px] flex-shrink-0 items-center justify-center overflow-clip rounded-10 border border-black/25 bg-black/10 text-black shadow-[3px_2px_10px_0px_rgba(3,7,18,0.1)]">
            {avatar ? (
              <img
                className="aspect-square h-full w-full object-contain"
                src={'https://picsum.photos/200'}
                alt={name || address || 'ens avatar'}
              />
            ) : (
              <Wallet size={20} />
            )}
          </div>
          {isConnected ? (
            <ConnectedState address={name || address || ''} font={font} />
          ) : isOpen ? (
            <LoadingState />
          ) : (
            <NotConnectedState font={font} />
          )}
        </Button>
        {isConnected && (
          <Button
            type="button"
            className={cn(
              theme?.radius || 'rounded-full',
              'h-10 min-w-max flex-shrink-0 gap-2.5 bg-black px-4 py-2 text-lg text-white shadow-[1px_1px_4px_0px_rgba(0,0,0,0.08)] hover:bg-black/80',
              isSigning &&
                'border border-black/5 bg-black/10 disabled:opacity-100',
              isVerified &&
                'border border-black/5 bg-white/50 hover:bg-white/50 disabled:opacity-100',
            )}
            disabled={disabled || isSigning || isVerified}
            onClick={handleSign}
          >
            {isSigning ? (
              <>
                <Loader
                  size={20}
                  className="animate-spin text-[rgba(106,106,111,1)]"
                />
                <span className="leading-none text-[rgba(106,106,111,1)]">
                  Checking
                </span>
              </>
            ) : isVerified ? (
              <>
                <CheckCircle size={20} className="text-teal-500" />
                <span className="leading-none text-teal-500">Verified</span>
              </>
            ) : (
              <>
                <Pencil size={20} />
                <span className="leading-none">Sign to verify</span>
              </>
            )}
          </Button>
        )}
      </div>
    );
  },
);

export default ConnectWallet;
