'use client';

import { ChainID } from '@formo/analytics';
import { chainIdToNameMapper } from '@formo/shared';
import {
  TokenGateCondition,
  TokenGateSettingRequirement,
  WalletCondition,
} from '@formo/types';
import { Check, Pen, X } from 'lucide-react';
import {
  AvailableConditionRecord,
  AvailableConditionSolanaRecord,
} from '~/constants/token-gate';
import { cn } from '~/lib/utils';

import { InfoTooltip } from '../../Builder/components/common/buttons/InfoTooltip';
import { Lock, SvgLoader } from '../../common';
import { TokenGateStatus } from '../../form/FormRenderer/TokenGateStep';

type ConditionProps = TokenGateCondition & {
  parentType: keyof TokenGateSettingRequirement;
  availableConditions:
    | AvailableConditionRecord
    | AvailableConditionSolanaRecord;
  onRemoveCondition?: (
    index: number,
    parentType: keyof TokenGateSettingRequirement,
  ) => void;
  onEditCondition?: (
    index: number,
    requirementType: keyof TokenGateSettingRequirement,
    conditionType: NonNullable<
      TokenGateSettingRequirement[typeof requirementType]
    >[number]['type'],
  ) => void;
  index: number;
  isResponder?: boolean;
  status?: TokenGateStatus;
};

const Tooltip = ({
  content,
  status,
}: {
  content: React.ReactNode;
  status?: TokenGateStatus;
}) => {
  return (
    <InfoTooltip
      className={cn(
        'ml-auto block aspect-square h-max max-h-none w-max max-w-none rounded-full border-none p-1 hover:bg-transparent',
        !status && 'bg-transparent',
        status === TokenGateStatus.VALID &&
          'bg-[rgba(235,247,240,1)] hover:bg-[rgba(235,247,240,1)]',
        status === TokenGateStatus.INVALID &&
          'bg-[rgba(242,242,242,1)] hover:bg-[rgba(242,242,242,1)]',
      )}
      isDark
      content={content}
      classNames={{
        content: 'max-w-max',
      }}
    >
      {!status && <Lock size={24} className="text-gray-500" strokeWidth={3} />}
      {status === TokenGateStatus.VALID && (
        <Check size={16} className="text-teal-500" strokeWidth={3} />
      )}
      {status === TokenGateStatus.INVALID && (
        <X size={16} className="text-gray-700" strokeWidth={3} />
      )}
      {status === TokenGateStatus.LOADING && (
        <SvgLoader size={24} className="animate-spin text-gray-500" />
      )}
    </InfoTooltip>
  );
};

const Condition: React.FC<ConditionProps> = ({
  name,
  type,
  availableConditions,
  onRemoveCondition = () => {},
  index,
  onEditCondition = () => {},
  parentType,
  isResponder = false,
  status,
  ...props
}) => {
  if (type === 'verified') {
    return (
      <li className="flex items-center py-0.5">
        <img
          src="/icons/condition-verify-identity.svg"
          alt="verify identity condition"
          className="h-10 w-10"
        />
        <span className="ml-2 text-base text-gray-900">{name}</span>
        {isResponder && (
          <Tooltip
            status={status}
            content={
              <>
                Requirements
                <div className="mt-1 max-w-[400px]">
                  <VerifyNFTTooltip fieldType={parentType} />
                </div>
              </>
            }
          />
        )}
      </li>
    );
  }

  const image =
    availableConditions[
      type as keyof (AvailableConditionRecord | AvailableConditionSolanaRecord)
    ]?.icon;

  return (
    <li className="group flex items-center py-0.5">
      <img
        src={
          image?.src
            ? // Insert -icon before .svg
              image.src.replace(/\.svg$/, '-icon.svg')
            : '/icons/condition-verify-identity.svg'
        }
        alt={image?.alt}
        className="h-10 w-10"
      />
      <span className="ml-2 text-base text-gray-900">{name}</span>

      {isResponder ? (
        <Tooltip
          status={status}
          content={
            <>
              Requirements
              <dl className="mt-1 grid gap-2 [grid-template-columns:_max-content_auto_auto]">
                {type === 'nft' || type === 'erc20' || type === 'native' ? (
                  <TokenNFTTooltip {...(props as any)} type={type} />
                ) : (
                  <></>
                )}
              </dl>
            </>
          }
        />
      ) : (
        <div className="ml-auto flex items-center gap-1.5 opacity-0 transition-opacity group-hover:opacity-100">
          <Pen
            size={18}
            className="cursor-pointer text-black opacity-20 transition-opacity hover:opacity-50"
            onClick={() => onEditCondition(index, parentType, type)}
          />
          <X
            size={18}
            className="cursor-pointer text-black opacity-20 transition-opacity hover:opacity-50"
            onClick={() => onRemoveCondition(index, parentType)}
          />
        </div>
      )}
    </li>
  );
};

const TokenNFTTooltip: React.FC<
  Exclude<WalletCondition, { type: 'verified' }>
> = ({ tokenName, chainId, tokenAddress, tokenSymbol, count, type }) => {
  const isNativeToken = type === 'native';

  return (
    <>
      <dt>Network</dt>:
      <dd>
        {chainIdToNameMapper[chainId as ChainID]} (ID: {chainId})
      </dd>
      {!isNativeToken && (
        <>
          <dt>Token</dt>:
          <dd>
            {tokenName} {tokenSymbol ? `(${tokenSymbol})` : null}{' '}
            <div>{tokenAddress}</div>
          </dd>
        </>
      )}
      <dt>Amount</dt>:
      <dd>{isNativeToken ? `${count} ${tokenSymbol}` : count}</dd>
    </>
  );
};

const VerifyNFTTooltip: React.FC<{
  fieldType: keyof TokenGateSettingRequirement;
}> = ({ fieldType }) => {
  switch (fieldType) {
    case 'discord':
      return (
        <span>You must sign in with Discord to verify your identity.</span>
      );
    case 'wallet':
      return (
        <span>
          You must connect your wallet and sign a message to verify your
          identity.
        </span>
      );
    case 'solana':
      return (
        <span>
          You must connect your wallet and sign a message to verify your
          identity.
        </span>
      );
    case 'twitter':
      return (
        <span>You must sign in with Twitter to verify your identity.</span>
      );
    case 'world-id':
      return (
        <span>You must sign in with World ID to verify your identity.</span>
      );
    case 'telegram':
      return (
        <span>You must sign in with Telegram to verify your identity.</span>
      );
    case 'farcaster':
      return (
        <span>You must sign in with Farcaster to verify your identity.</span>
      );
  }
};

export default Condition;
