import { PublicKey } from '@solana/web3.js';

export function isValidSolanaAddress(address: string) {
  try {
    const publicKey = new PublicKey(address);
    return PublicKey.isOnCurve(publicKey.toBuffer());
  } catch (error) {
    return false;
  }
}
