'use client';

import {
  CHAIN_TYPE,
  chainIdToNativeTokenMapper,
  chainIdToNativeTokenNameMapper,
} from '@formo/shared';
import {
  PickConditionType,
  WalletCondition,
  WalletSolanaCondition,
} from '@formo/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { CurrentCondition } from '~/app/context/TokenGateContext';
import useTokenGate from '~/app/hooks/useTokenGate';
import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import InputNumber from '~/components/ui/input-number';
import { Label } from '~/components/ui/label';
import { useToast } from '~/components/ui/use-toast';
import { ChainId } from '~/constants/token-gate';
import { isValidSolanaAddress } from '~/utils/validateSolanaAddress/validateSolanaAddress';

import TokenSelect, { Token } from './TokenSelect';

const tokenConditionSchema = z
  .object({
    chainId: z.union([z.number().min(1).int(), z.string().min(1)]),
    address: z.string().trim().optional(),
    amount: z.number().refine((v) => v > 0, 'Amount is required'),
    name: z.string().trim().min(1, 'Name is required'),
    type: z.enum(['erc20', 'nft', 'native']),
    requirementType: z.string().optional(), // Thêm trường requirementType
  })
  .refine(
    (data) => {
      if (data.type === 'native') return true;

      return data.address && data.address.length > 0;
    },
    { message: 'Address is required', path: ['address'] },
  )
  .refine(
    (data) => {
      if (data.type === 'native') return true;

      return (
        data.address &&
        (data.address.startsWith('0x') || isValidSolanaAddress(data.address))
      );
    },
    { message: 'Invalid address', path: ['address'] },
  );

export type TokenConditionSchema = Omit<
  z.infer<typeof tokenConditionSchema>,
  'chainId'
> & {
  chainId: ChainId;
  requirementType?: CHAIN_TYPE;
};

const TextMap = {
  nft: {
    label: 'Select an NFT',
    amount: {
      step: 1,
      decimals: 0,
      placeholder: 'Enter an amount',
    },
    placeholder: 'Owns an NFT',
  },
  erc20: {
    label: 'Select a token',
    amount: {
      step: 0.000_000_000_000_000_1,
      decimals: 16,
      placeholder: '0.01',
    },
    placeholder: 'Owns an ERC20 Token',
  },
  native: {
    label: 'Select a network',
    amount: {
      step: 0.000_000_000_000_000_1,
      decimals: 16,
      placeholder: '0.01',
    },
    placeholder: 'Owns a Native Token',
  },
} as const;

const TokenConditionForm: React.FC = () => {
  const { toast } = useToast();

  const {
    onAddRequirement,
    onUpdateRequirement,
    openConditionDrawer,
    onCloseConditionDrawer,
    currentCondition,
  } = useTokenGate();

  const castedCondition = currentCondition as PickConditionType<
    CurrentCondition,
    'erc20' | 'native' | 'nft'
  > | null;

  const {
    getValues,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<TokenConditionSchema>({
    defaultValues: {
      type:
        castedCondition?.type ||
        (openConditionDrawer?.type as 'nft' | 'erc20' | 'native'),
      address: (castedCondition as any)?.tokenAddress || '',
      name: castedCondition?.name || '',
      amount: castedCondition?.count || 0,
      chainId:
        openConditionDrawer?.requirementType === 'solana'
          ? (castedCondition?.chainId as ChainId) || 'mainnet'
          : (castedCondition?.chainId as ChainId) || 1,
    },
    resolver: zodResolver(tokenConditionSchema),
  });
  const { chainId, address, amount, name } = getValues();

  const [selectedToken, setSelectedToken] = useState<Token | null>(null);

  if (!openConditionDrawer) return null;

  const type = openConditionDrawer.type as
    | Exclude<WalletCondition['type'], 'verified'>
    | Exclude<WalletSolanaCondition['type'], 'verified'>;

  const { label, amount: amountText, placeholder } = TextMap[type];

  const isNativeToken = openConditionDrawer.type === 'native';

  const onSubmit = async (values: TokenConditionSchema) => {
    try {
      const isValid = await trigger();
      if (!isValid || !openConditionDrawer) return;
      if (castedCondition?.index) {
        onUpdateRequirement(castedCondition.index, {
          type: castedCondition.type as 'nft' | 'erc20' | 'native',
          chainId: values.chainId as any,
          tokenName: isNativeToken
            ? chainIdToNativeTokenMapper[chainId]
            : selectedToken?.name || '',
          tokenSymbol: isNativeToken
            ? chainIdToNativeTokenNameMapper[chainId]
            : selectedToken?.symbol || '',
          tokenAddress: isNativeToken
            ? (chainIdToNativeTokenNameMapper[chainId] as string)
            : values.address || '',
          count: values.amount,
          name: values.name,
          decimals: selectedToken?.decimals || 0,
        });
      } else {
        onAddRequirement({
          type: openConditionDrawer.type as 'nft' | 'erc20' | 'native',
          chainId: values.chainId as any,
          count: values.amount,
          name: values.name,
          tokenAddress: isNativeToken
            ? (chainIdToNativeTokenNameMapper[chainId || 1] as string)
            : values.address || '',
          tokenName: isNativeToken
            ? chainIdToNativeTokenMapper[chainId]
            : selectedToken?.name || '',
          tokenSymbol: isNativeToken
            ? chainIdToNativeTokenNameMapper[chainId]
            : selectedToken?.symbol || '',
          decimals: selectedToken?.decimals || 0,
        });
      }

      onCloseConditionDrawer();
    } catch (error: any) {
      console.log(error);
      toast({
        title: 'Failed to add requirement',
        description: error.message,
      });
    }
  };

  return (
    <form
      className="flex flex-col gap-8 text-base"
      onSubmit={handleSubmit(onSubmit)}
    >
      {label && (
        <TokenSelect
          mode={
            openConditionDrawer?.requirementType === CHAIN_TYPE.SOLANA
              ? CHAIN_TYPE.SOLANA
              : CHAIN_TYPE.EVM
          }
          setValue={setValue}
          values={{ address, chainId }}
          errors={errors}
          trigger={trigger}
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          label="Select Token"
          type={type}
          dirtyFields={dirtyFields}
        />
      )}
      <div className="flex flex-col">
        <Label className="text-base font-medium text-black">
          Minimum amount
          <span className="px-1 text-base text-red-500">*</span>
        </Label>
        <div className="flex items-center gap-2.5 mt-2.5">
          <InputNumber
            classNames={{
              input: 'placeholder:text-gray-600',
              root: '[--input-number-border:rgb(var(--color-gray-200))] w-1/2',
            }}
            placeholder={amountText.placeholder}
            // 16 decimals
            step={amountText.step}
            decimals={amountText.decimals}
            min={0}
            showButtons={false}
            value={amount}
            name="amount"
            onChange={(v) => {
              setValue('amount', v);
              trigger('amount');
            }}
          />
          {isNativeToken && (
            <div className="text-black text-sm font-medium">
              {chainIdToNativeTokenMapper[chainId]}
            </div>
          )}
        </div>
        {errors.amount?.message && (
          <p className="mt-1 text-sm text-red-500">{errors.amount.message}</p>
        )}
      </div>
      <div className="flex flex-col">
        <Label className="text-base font-medium text-black">
          Name your requirement
          <span className="px-1 text-base text-red-500">*</span>
        </Label>
        <Input
          className="mt-2.5 border-gray-200 text-base focus:text-base placeholder:text-base placeholder:text-gray-600 focus-visible:border-gray-200"
          value={name}
          onChange={(e) => {
            setValue('name', e.target.value, {
              shouldDirty: true,
            });
            trigger('name');
          }}
          placeholder={placeholder}
          name="name"
        />
        {errors.name?.message && (
          <p className="mt-1 text-sm text-red-500">{errors.name.message}</p>
        )}
      </div>
      <Button type="submit" className="ml-auto rounded-lg px-5 py-3 text-base">
        {castedCondition?.index ? 'Update' : 'Add'} requirement
      </Button>
    </form>
  );
};

export default TokenConditionForm;
