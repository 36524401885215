import { TokenGateCondition, TokenGateSettingRequirement } from '@formo/types';
import TokenConditionForm from '~/app/_components/settings/TokenGate/tokens/TokenConditionForm';

export type AvailableCondition<
  T extends
    keyof TokenGateSettingRequirement = keyof TokenGateSettingRequirement,
> = {
  name: string;
  type: TokenGateCondition['type'];
  icon: {
    src: string;
    alt: string;
  };
  form: React.FC;
  requirementType: T;
};

export type AvailableConditionRecord = Partial<
  Record<
    Exclude<
      NonNullable<TokenGateSettingRequirement['wallet']>[number]['type'],
      'verified'
    >,
    AvailableCondition<'wallet'>
  >
>;

export type AvailableConditionSolanaRecord = Partial<
  Record<
    Exclude<
      NonNullable<TokenGateSettingRequirement['solana']>[number]['type'],
      'verified'
    >,
    AvailableCondition<'solana'>
  >
>;

export type AvailableRequirement<
  T extends
    keyof TokenGateSettingRequirement = keyof TokenGateSettingRequirement,
> = {
  name: string;
  type: T;
  icon: {
    src: string;
    alt: string;
  };
  conditions: AvailableConditionRecord | AvailableConditionSolanaRecord;
};

const availableWalletRequirements: AvailableRequirement<'wallet'> = {
  name: 'Connect Wallet (EVM)',
  type: 'wallet',
  icon: {
    src: '/icons/connect-wallet-field.svg',
    alt: 'connect wallet requirement icon',
  },
  conditions: {
    nft: {
      name: 'NFT',
      type: 'nft',
      icon: {
        src: '/icons/condition-nft.svg',
        alt: 'nft condition icon',
      },
      form: TokenConditionForm,
      requirementType: 'wallet',
    },
    erc20: {
      name: 'ERC20 Token',
      type: 'erc20',
      icon: {
        src: '/icons/condition-erc20-token.svg',
        alt: 'token condition icon',
      },
      form: TokenConditionForm,
      requirementType: 'wallet',
    },
    native: {
      name: 'Native Token',
      type: 'native',
      icon: {
        src: '/icons/condition-native-token.svg',
        alt: 'token condition icon',
      },
      form: TokenConditionForm,
      requirementType: 'wallet',
    },
  },
};

const availableDiscordRequirements: AvailableRequirement<'discord'> = {
  name: 'Connect Discord',
  type: 'discord',
  icon: {
    src: '/icons/connect-discord-field.svg',
    alt: 'connect discord requirement icon',
  },
  conditions: {},
};

const availableTwitterRequirements: AvailableRequirement<'twitter'> = {
  name: 'Connect Twitter',
  type: 'twitter',
  icon: {
    src: '/icons/connect-x-field.svg',
    alt: 'connect twitter requirement icon',
  },
  conditions: {},
};

const availableFarcasterRequirements: AvailableRequirement<'farcaster'> = {
  name: 'Connect Farcaster',
  type: 'farcaster',
  icon: {
    src: '/icons/connect-farcaster-field.svg',
    alt: 'connect farcaster requirement icon',
  },
  conditions: {},
};

const availableTelegramRequirements: AvailableRequirement<'telegram'> = {
  name: 'Connect Telegram',
  type: 'telegram',
  icon: {
    src: '/icons/connect-telegram-field.svg',
    alt: 'connect telegram requirement icon',
  },
  conditions: {},
};

const availableWorldIdRequirements: AvailableRequirement<'world-id'> = {
  name: 'Connect World ID',
  type: 'world-id',
  icon: {
    src: '/icons/connect-world-id-field.svg',
    alt: 'connect world id requirement icon',
  },
  conditions: {},
};

const availableSolanaRequirements: AvailableRequirement<'solana'> = {
  name: 'Connect Wallet (Solana)',
  type: 'solana',
  icon: {
    src: '/icons/connect-wallet-field.svg',
    alt: 'connect solana requirement icon',
  },

  conditions: {
    nft: {
      name: 'NFT',
      type: 'nft',
      icon: {
        src: '/icons/condition-nft.svg',
        alt: 'nft condition icon',
      },
      form: TokenConditionForm,
      requirementType: 'solana',
    },

    native: {
      name: 'Native Token',
      type: 'native',
      icon: {
        src: '/icons/condition-native-token.svg',
        alt: 'token condition icon',
      },
      form: TokenConditionForm,
      requirementType: 'solana',
    },
  },
};

export const availableRequirements = {
  wallet: availableWalletRequirements,
  solana: availableSolanaRequirements,
  discord: availableDiscordRequirements,
  twitter: availableTwitterRequirements,
  telegram: availableTelegramRequirements,
  farcaster: availableFarcasterRequirements,
  'world-id': availableWorldIdRequirements,
};

// Define an array of keys in the desired order
const chainOrder: (keyof typeof chains)[] = [
  1, // Ethereum
  11155111, // Ethereum Sepolia
  2741, // Abstract Mainnet
  11124, // Abstract Testnet
  42161, // Arbitrum
  421613, // Arbitrum Sepolia
  42170, // Arbitrum Nova
  43114, // Avalanche
  43113, // Avalanche Fuji
  8453, // Base
  84532, // Base Sepolia
  80094, // Berachain
  80084, // Berachain bArtio
  81457, // Blast
  168587773, // Blast Sepolia
  56, // BNB Smart Chain
  97, // BNB Smart Chain Testnet
  60808, // BOB
  808813, // BOB Sepolia
  42220, // Celo
  44787, // Celo Alfajores
  252, // Fraxtal
  2522, // Fraxtal Testnet
  100, // Gnosis
  10200, // Gnosis Testnet
  690, // Redstone
  17069, // Redstone Garnet
  13371, // ImmutableX
  13473, // ImmutableX Testnet
  57073, // Ink Mainnet
  763373, // Ink Sepolia
  8217, // Kaia
  1001, // Kaia Kairos
  37111, // Lens Sepolia
  59144, // Linea
  59141, // Linea Sepolia
  5000, // Mantle
  5003, // Mantle Sepolia
  34443, // Mode
  919, // Mode Testnet
  2818, // Morph
  2810, // Morph Testnet
  10, // Optimism
  11155420, // Optimism Sepolia
  911867, // Odyssey
  137, // Polygon
  80002, // Polygon Amoy
  1101, // Polygon ZkEVM
  2442, // Polygon ZkEVM Cardona
  2020, // Ronin
  2021, // Ronin Saigon
  534352, // Scroll
  534351, // Scroll Sepolia
  1329, // Sei
  1328, // Sei Testnet
  1868, // Soneium
  1946, // Soneium Minato
  146, // Sonic
  57054, // Sonic Blaze
  1513, // Story Testnet
  130, // Unichain
  1301, // Unichain Sepolia
  480, // World Chain
  4801, // World Chain Sepolia
  241320161, // XMTP Sepolia
  324, // zkSync Era
  300, // zkSync Era Sepolia
  7777777, // Zora
  999999999, // Zora Sepolia
];

export const chains = {
  1: {
    id: 1,
    name: 'Ethereum',
    icon: {
      src: '/icons/eth-icon.svg',
      alt: 'Ethereum',
    },
  },
  11155111: {
    id: 11155111,
    name: 'Ethereum Sepolia',
    icon: {
      src: '/icons/eth-icon.svg',
      alt: 'Ethereum Sepolia',
    },
  },
  8453: {
    id: 8453,
    name: 'Base',
    icon: {
      src: '/icons/base-icon.svg',
      alt: 'Base',
    },
  },
  84532: {
    id: 84532,
    name: 'Base Sepolia',
    icon: {
      src: '/icons/base-icon.svg',
      alt: 'Base Sepolia',
    },
  },
  42161: {
    id: 42161,
    name: 'Arbitrum',
    icon: {
      src: '/icons/arbitrum-icon.svg',
      alt: 'Arbitrum',
    },
  },
  42170: {
    id: 42170,
    name: 'Arbitrum Nova',
    icon: {
      src: '/icons/arbnova-icon.svg',
      alt: 'Arbitrum Nova',
    },
  },
  421613: {
    id: 421613,
    name: 'Arbitrum Sepolia',
    icon: {
      src: '/icons/arbitrum-icon.svg',
      alt: 'Arbitrum Sepolia',
    },
  },
  10: {
    id: 10,
    name: 'Optimism',
    icon: {
      src: '/icons/optimism-icon.svg',
      alt: 'Optimism',
    },
  },
  11155420: {
    id: 11155420,
    name: 'Optimism Sepolia',
    icon: {
      src: '/icons/optimism-icon.svg',
      alt: 'Optimism Sepolia',
    },
  },
  137: {
    id: 137,
    name: 'Polygon',
    icon: {
      src: '/icons/polygon-icon.svg',
      alt: 'Polygon',
    },
  },
  80002: {
    id: 80002,
    name: 'Polygon Amoy',
    icon: {
      src: '/icons/polygon-icon.svg',
      alt: 'Polygon Amoy',
    },
  },
  1101: {
    id: 1101,
    name: 'Polygon ZkEVM',
    icon: {
      src: '/icons/polygonzkevm-icon.svg',
      alt: 'Polygon ZkEVM',
    },
  },
  2442: {
    id: 2442,
    name: 'Polygon ZkEVM Cardona',
    icon: {
      src: '/icons/polygonzkevm-icon.svg',
      alt: 'Polygon ZkEVM Cardona',
    },
  },
  43114: {
    id: 43114,
    name: 'Avalanche',
    icon: {
      src: '/icons/avalanche-icon.svg',
      alt: 'Avalanche',
    },
  },
  43113: {
    id: 43113,
    name: 'Avalanche Fuji',
    icon: {
      src: '/icons/avalanche-icon.svg',
      alt: 'Avalanche Fuji',
    },
  },
  5000: {
    id: 5000,
    name: 'Mantle',
    icon: {
      src: '/icons/mantle-icon.svg',
      alt: 'Mantle',
    },
  },
  5003: {
    id: 5003,
    name: 'Mantle Sepolia',
    icon: {
      src: '/icons/mantle-icon.svg',
      alt: 'Mantle Sepolia',
    },
  },
  59144: {
    id: 59144,
    name: 'Linea',
    icon: {
      src: '/icons/linea-icon.svg',
      alt: 'Linea',
    },
  },
  59141: {
    id: 59141,
    name: 'Linea Sepolia',
    icon: {
      src: '/icons/linea-icon.svg',
      alt: 'Linea Sepolia',
    },
  },
  534352: {
    id: 534352,
    name: 'Scroll',
    icon: {
      src: '/icons/scroll-icon.svg',
      alt: 'Scroll',
    },
  },
  534351: {
    id: 534351,
    name: 'Scroll Sepolia',
    icon: {
      src: '/icons/scroll-icon.svg',
      alt: 'Scroll Sepolia',
    },
  },
  324: {
    id: 324,
    name: 'zkSync Era',
    icon: {
      src: '/icons/zksync-icon.svg',
      alt: 'zkSync Era',
    },
  },
  300: {
    id: 300,
    name: 'zkSync Era Sepolia',
    icon: {
      src: '/icons/zksync-icon.svg',
      alt: 'zkSync Era Sepolia',
    },
  },
  81457: {
    id: 81457,
    name: 'Blast',
    icon: {
      src: '/icons/blast-icon.svg',
      alt: 'Blast',
    },
  },
  168587773: {
    id: 168587773,
    name: 'Blast Sepolia',
    icon: {
      src: '/icons/blast-icon.svg',
      alt: 'Blast Sepolia',
    },
  },
  56: {
    id: 56,
    name: 'BNB Smart Chain',
    icon: {
      src: '/icons/bnb-icon.svg',
      alt: 'BNB Smart Chain',
    },
  },
  97: {
    id: 97,
    name: 'BNB Smart Chain Testnet',
    icon: {
      src: '/icons/bnb-icon.svg',
      alt: 'BNB Smart Chain Testnet',
    },
  },
  1513: {
    id: 1513,
    name: 'Story Testnet',
    icon: {
      src: '/icons/story-icon.svg',
      alt: 'Story Testnet',
    },
  },
  480: {
    id: 480,
    name: 'World Chain',
    icon: {
      src: '/icons/world-chain-icon.svg',
      alt: 'World Chain',
    },
  },
  4801: {
    id: 4801,
    name: 'World Chain Sepolia',
    icon: {
      src: '/icons/world-chain-icon.svg',
      alt: 'World Chain Sepolia',
    },
  },
  80094: {
    id: 80094,
    name: 'Berachain',
    icon: {
      src: '/icons/berachain-icon.svg',
      alt: 'Berachain',
    },
  },
  80084: {
    id: 80084,
    name: 'Berachain bArtio',
    icon: {
      src: '/icons/berachain-icon.svg',
      alt: 'Berachain bArtio',
    },
  },
  7777777: {
    id: 7777777,
    name: 'Zora',
    icon: {
      src: '/icons/zora-icon.svg',
      alt: 'Zora',
    },
  },
  999999999: {
    id: 999999999,
    name: 'Zora Sepolia',
    icon: {
      src: '/icons/zora-icon.svg',
      alt: 'Zora Sepolia',
    },
  },
  252: {
    id: 252,
    name: 'Fraxtal',
    icon: {
      src: '/icons/fraxtal-icon.svg',
      alt: 'Fraxtal',
    },
  },
  2522: {
    id: 2522,
    name: 'Fraxtal Testnet',
    icon: {
      src: '/icons/fraxtal-icon.svg',
      alt: 'Fraxtal Testnet',
    },
  },
  1868: {
    id: 1868,
    name: 'Soneium',
    icon: {
      src: '/icons/soneium-icon.ico',
      alt: 'Soneium',
    },
  },
  1946: {
    id: 1946,
    name: 'Soneium Minato',
    icon: {
      src: '/icons/soneium-icon.ico',
      alt: 'Soneium Minato',
    },
  },
  2741: {
    id: 2741,
    name: 'Abstract Mainnet',
    icon: {
      src: '/icons/abstract-icon.png',
      alt: 'Abstract Mainnet',
    },
  },
  11124: {
    id: 11124,
    name: 'Abstract Testnet',
    icon: {
      src: '/icons/abstract-icon.png',
      alt: 'Abstract Testnet',
    },
  },
  13371: {
    id: 13371,
    name: 'ImmutableX',
    icon: {
      src: '/icons/immutablex-icon.svg',
      alt: 'ImmutableX',
    },
  },
  13473: {
    id: 13473,
    name: 'ImmutableX Testnet',
    icon: {
      src: '/icons/immutablex-icon.svg',
      alt: 'ImmutableX Testnet',
    },
  },
  34443: {
    id: 34443,
    name: 'Mode',
    icon: {
      src: '/icons/mode-icon.svg',
      alt: 'Mode',
    },
  },
  919: {
    id: 919,
    name: 'Mode Testnet',
    icon: {
      src: '/icons/mode-icon.svg',
      alt: 'Mode Testnet',
    },
  },
  100: {
    id: 100,
    name: 'Gnosis',
    icon: {
      src: '/icons/gnosis-icon.svg',
      alt: 'Gnosis',
    },
  },
  10200: {
    id: 10200,
    name: 'Gnosis Testnet',
    icon: {
      src: '/icons/gnosis-icon.svg',
      alt: 'Gnosis Testnet',
    },
  },
  42220: {
    id: 42220,
    name: 'Celo',
    icon: {
      src: '/icons/celo-icon.svg',
      alt: 'Celo',
    },
  },
  44787: {
    id: 44787,
    name: 'Celo Alfajores',
    icon: {
      src: '/icons/celo-icon.svg',
      alt: 'Celo Alfajores',
    },
  },
  130: {
    id: 130,
    name: 'Unichain',
    icon: {
      src: '/icons/unichain-icon.svg',
      alt: 'Unichain',
    },
  },
  1301: {
    id: 1301,
    name: 'Unichain Sepolia',
    icon: {
      src: '/icons/unichain-icon.svg',
      alt: 'Unichain Sepolia',
    },
  },
  8217: {
    id: 8217,
    name: 'Kaia',
    icon: {
      src: '/icons/kaia-icon.svg',
      alt: 'Kaia',
    },
  },
  1001: {
    id: 1001,
    name: 'Kaia Kairos',
    icon: {
      src: '/icons/kaia-icon.svg',
      alt: 'Kaia Kairos',
    },
  },
  911867: {
    id: 911867,
    name: 'Odyssey',
    icon: {
      src: '/icons/odyssey-icon.svg',
      alt: 'Odyssey',
    },
  },
  690: {
    id: 690,
    name: 'Redstone',
    icon: {
      src: '/icons/redstone-icon.jpg',
      alt: 'Redstone',
    },
  },
  17069: {
    id: 17069,
    name: 'Redstone Garnet',
    icon: {
      src: '/icons/redstone-icon.jpg',
      alt: 'Redstone Garnet',
    },
  },
  2818: {
    id: 2818,
    name: 'Morph',
    icon: {
      src: '/icons/morph-icon.jpeg',
      alt: 'Morph',
    },
  },
  2810: {
    id: 2810,
    name: 'Morph Testnet',
    icon: {
      src: '/icons/morph-icon.jpeg',
      alt: 'Morph Testnet',
    },
  },
  57073: {
    id: 57073,
    name: 'Ink Mainnet',
    icon: {
      src: '/icons/ink-icon.jpg',
      alt: 'Ink Mainnet',
    },
  },
  763373: {
    id: 763373,
    name: 'Ink Sepolia',
    icon: {
      src: '/icons/ink-icon.jpg',
      alt: 'Ink Sepolia',
    },
  },
  146: {
    id: 146,
    name: 'Sonic',
    icon: {
      src: '/icons/sonic-icon.svg',
      alt: 'Sonic',
    },
  },
  57054: {
    id: 57054,
    name: 'Sonic Blaze',
    icon: {
      src: '/icons/sonic-icon.svg',
      alt: 'Sonic Blaze',
    },
  },
  2020: {
    id: 2020,
    name: 'Ronin',
    icon: {
      src: '/icons/ronin-icon.svg',
      alt: 'Ronin',
    },
  },
  2021: {
    id: 2021,
    name: 'Ronin Saigon',
    icon: {
      src: '/icons/ronin-icon.svg',
      alt: 'Ronin Saigon',
    },
  },
  37111: {
    id: 37111,
    name: 'Lens Sepolia',
    icon: {
      src: '/icons/lens-icon.svg',
      alt: 'Lens Sepolia',
    },
  },
  241320161: {
    id: 241320161,
    name: 'XMTP Sepolia',
    icon: {
      src: '/icons/xmtp-icon.svg',
      alt: 'XMTP Sepolia',
    },
  },
  60808: {
    id: 60808,
    name: 'BOB',
    icon: {
      src: '/icons/bob-icon.svg',
      alt: 'BOB',
    },
  },
  808813: {
    id: 808813,
    name: 'BOB Sepolia',
    icon: {
      src: '/icons/bob-icon.svg',
      alt: 'BOB Sepolia',
    },
  },
  1329: {
    id: 1329,
    name: 'Sei',
    icon: {
      src: '/icons/sei-icon.svg',
      alt: 'Sei',
    },
  },
  1328: {
    id: 1328,
    name: 'Sei Testnet',
    icon: {
      src: '/icons/sei-icon.svg',
      alt: 'Sei Testnet',
    },
  },
} as const;

export const solana_chains = {
  mainnet: {
    id: 'mainnet',
    name: 'Solana Mainet',
    icon: {
      src: '/icons/solana.svg',
      alt: 'Solana',
    },
  },
  devnet: {
    id: 'devnet',
    name: 'Solana Devnet',
    icon: {
      src: '/icons/solana.svg',
      alt: 'Solana',
    },
  },
} as const;

export type ChainId = keyof typeof chains | keyof typeof solana_chains;

// Map over the ordered keys to get the ordered chains
export const orderedChains = chainOrder.map((key) => chains[key]);

export enum SearchBy {
  Name = 'name',
  Symbol = 'symbol',
  Address = 'address',
}
