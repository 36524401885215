'use client';

import { Token } from './TokenSelect';

type TokenSelectItemProps = {
  token: Token;
  onSelect?: (token: Token) => void;
};

const TokenSelectItem: React.FC<TokenSelectItemProps> = ({
  onSelect,
  token,
}) => {
  return (
    <button
      key={token.address}
      type="button"
      className="flex w-full gap-2.5 overflow-clip rounded-md px-2.5 py-3 transition-colors hover:bg-black/5 active:bg-black/10"
      onClick={() => {
        onSelect && onSelect(token);
      }}
    >
      {token.logoUri ? (
        <img
          src={token.logoUri}
          alt={token.name}
          className="w-7.5 h-7.5 rounded-full object-contain flex-shrink-0"
        />
      ) : (
        <div className="w-7.5 h-7.5 rounded-full bg-black/10 flex items-center justify-center flex-shrink-0">
          {token.name[0]?.toUpperCase()}
        </div>
      )}

      <div className="overflow-clip text-left flex-shrink">
        <div className="flex items-end gap-2">
          <span className="line-clamp-1 break-word text-base font-medium leading-none text-black">
            {token.name}
          </span>
          <span className="text-sm font-medium leading-none text-gray-700">
            {token.symbol}
          </span>
        </div>
        <p className="mt-0.5 text-xs font-medium leading-none text-gray-600">
          {token.address.slice(0, 5)}...
          {token.address.slice(-5)}
        </p>
      </div>
    </button>
  );
};

export default TokenSelectItem;
