import { IdentitySchema } from '@formo/types';
import { PublicKey } from '@solana/web3.js';
import { FC } from 'react';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { isAddress, verifyMessage } from 'viem';

import { ConnectDiscord } from '../../fields/ConnectDiscord';
import { ConnectFarcaster } from '../../fields/ConnectFarcaster';
import { ConnectSolana } from '../../fields/ConnectSolana';
import { ConnectTelegram } from '../../fields/ConnectTelegram';
import { ConnectTwitter } from '../../fields/ConnectTwitter';
import { ConnectWallet, WalletValue } from '../../fields/ConnectWallet';
import ConnectWorldId from '../../fields/ConnectWorldId/ConnectWorldId';

type Specs<T extends string = string> = IdentitySchema['fieldSpecs'] & {
  type: T;
};

type IdentityMapper = Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (props: { fieldSpecs: Specs }) => {
    Component: FC<any>;
    validation?: Omit<
      RegisterOptions<FieldValues, string>,
      'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    >;
  }
>;

export const identityMapper: IdentityMapper = {
  discord: ({ fieldSpecs }) => ({
    Component: ConnectDiscord,
    validation: {
      required: {
        value: fieldSpecs?.required,
        message: 'Must connect Discord',
      },
    },
  }),
  farcaster: ({ fieldSpecs }) => ({
    Component: ConnectFarcaster,
    validation: {
      required: {
        value: fieldSpecs?.required,
        message: 'Must connect Farcaster',
      },
    },
  }),
  wallet: ({ fieldSpecs }) => ({
    Component: ConnectWallet,
    validation: {
      validate: async (value: WalletValue) => {
        try {
          if (!fieldSpecs.required) return true;
          if (!value) return 'Must connect wallet';
          if (!value.address) return 'Must connect wallet';
          if (!value.signature) return 'You have not signed the message';
          const validAddress = isAddress(value.address);
          if (!validAddress) return 'Invalid address';
          const valid = verifyMessage(value);
          if (!valid) return 'Invalid signature';
          return true;
        } catch (error) {
          console.error(JSON.stringify(error));
          return false;
        }
      },
      required: {
        value: fieldSpecs?.required,
        message: 'Must connect and sign message to verify',
      },
    },
  }),
  twitter: ({ fieldSpecs }) => ({
    Component: ConnectTwitter,
    validation: {
      required: {
        value: fieldSpecs?.required,
        message: 'Must connect Twitter',
      },
    },
  }),
  'world-id': ({ fieldSpecs }) => ({
    Component: ConnectWorldId,
    validation: {
      required: {
        value: fieldSpecs?.required,
        message: 'Must connect World ID',
      },
    },
  }),
  telegram: ({ fieldSpecs }) => ({
    Component: ConnectTelegram,
    validation: {
      required: {
        value: fieldSpecs?.required,
        message: 'Must connect Telegram',
      },
    },
  }),
  solana: ({ fieldSpecs }) => ({
    Component: ConnectSolana,
    validation: {
      validate: async (value: WalletValue) => {
        try {
          if (!fieldSpecs.required) return true;
          if (!value) return 'Must connect wallet';
          if (!value.address) return 'Must connect wallet';
          const key = new PublicKey(value.address);
          const validAddress = PublicKey.isOnCurve(key.toBytes());
          if (!validAddress) return 'Invalid address';
          if (!value.signature) return 'You have not signed the message';
          return true;
        } catch (error) {
          console.error(JSON.stringify(error));
          return false;
        }
      },
      required: {
        value: fieldSpecs?.required,
        message: 'Must connect and sign message to verify',
      },
    },
  }),
  // Add more identity field types here
};
